.projects .modal-dialog form{
  padding: 32px;
}

.status-badge{
  font-size:14px;
  line-height:14px;
  font-weight: 500;
  text-transform: capitalize;
  border-radius:10px;
  color:white !important;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 4px 6px;
  min-width: 50px;
}

.status-badge.new{
  background-color: #319AE5;
}

.status-badge.active{
  background-color: #0CA789;
}

.status-badge.inactive{
  background-color: #E45A73;
}

.status-badge.closed{
  background-color: #454948;
}

.project-settings-modal{

  .status-dropdown{
    display: flex;
    align-items: center;
  }

  .status-badge{
    margin-left:16px;
    margin-right:8px;
  }
  
  .status-menu{
    right: -20px;
    left: unset;
    top: 35px !important;
    padding-top:16px;

    &:before{
      right: 28px;
      left: unset;
    }
  }

  .modal-footer{
    position: sticky;
    bottom: 0;
    width: 100%;
    background-color: white;
  }
} 

.projects table{
  table-layout: fixed;

  .user-row {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  li:not(:first-of-type){
    margin-left:-8px;
  }

  .avatar{
    border: 2px solid white;
  }
}

.project-detail, .task-detail{

  .page-header a, .page-header span, .page-header p, .page-header h2{
    color: var(--text-color);
  }

  .page-header p{
    margin:0;
  }

  .page-header h2{
    display:flex;
    gap:10px;
    align-items: center;
  }

  a{
    text-decoration:underline;
  }

  .col-container{
    gap:16px;
    margin-right: 16px;
  }

  .col{
    padding-left: unset !important;
    padding-right: unset !important;
  }

  .badge{
    margin-right:8px;
  }

  .card{
    margin-bottom: 16px;
    height: calc(100% - 16px);
  }

  .empty{
    margin-left:8px;
  }

  .actions-menu {
    left: unset;
    right: 0px;
    width: 180px;

    &:before {
      right: 28px;
      left: unset;
    }

    ul li button{
      text-align:left;
    }
  }

  .users-list{
    display:flex;
    gap:20px;
    flex-wrap:wrap;
    li{
      display:flex;
      flex-direction:column;
      align-items:center;
      p{
        margin-top:4px;
      }
    }
  }

  .project-link{
    display:flex;
    align-items: center;
    height:30px;
    margin:10px 0;
  }

  .actions{
    display:flex;
    margin-left:32px;
    gap:8px;

    button{
      min-height:unset;
      width: 28px;
      height: 28px;
    }

    &.hidden{
      display:none;
    }
  }

  .modal-dialog{
    .detail-modal-body{
      padding:20px;
    }

    .upload-button svg{
      margin-left: 16px;
    }
  }

}

.task-detail{
  .page-header h2 svg{
    width:20px;
    height:20px;
  }
}