/* ###### Backgrounds ###### */

.bg-secondary {
  background-color: var(--secondary) !important;
}

a.bg-secondary {
  &:hover, &:focus {
    background-color: var(--secondary) !important;
  }
}

button.bg-secondary {
  &:hover, &:focus {
    background-color: var(--secondary) !important;
  }
}

.bg-success {
  background-color: $success !important;
}

a.bg-success {
  &:hover, &:focus {
    background-color: $success !important;
  }
}

button.bg-success {
  &:hover, &:focus {
    background-color: $success !important;
  }
}

.bg-info {
  background-color: $info !important;
}

a.bg-info {
  &:hover, &:focus {
    background-color: $info !important;
  }
}

button.bg-info {
  &:hover, &:focus {
    background-color: $info  !important;
  }
}

.bg-warning {
  background-color: $warning  !important;
}

a.bg-warning {
  &:hover, &:focus {
    background-color: $warning !important;
  }
}

button.bg-warning {
  &:hover, &:focus {
    background-color: $warning !important;
  }
}

.bg-danger {
  background-color: $danger !important;
}

a.bg-danger {
  &:hover, &:focus {
    background-color: $danger !important;
  }
}

button.bg-danger {
  &:hover, &:focus {
    background-color: $danger !important;
  }
}

.bg-light {
  background-color: var(--secondary-light) !important;
  color:var(--secondary-text-color)
}

a.bg-light {
  &:hover, &:focus {
    background-color: var(--secondary-dark) !important;
  }
}

button.bg-light {
  &:hover, &:focus {
    background-color: var(--secondary-dark) !important;
  }
}

.bg-dark {
  background-color: black !important;
}

a.bg-dark {
  &:hover, &:focus {
    background-color: black !important;
  }
}

button.bg-dark {
  &:hover, &:focus {
    background-color: black !important;
  }
}

.bg-white {
  background-color: white !important;
}

.bg-transparent {
  background-color: transparent !important;
}

//.bg-primary-gradient {
//  background: linear-gradient(to right, #744dfe 0%, #71a7f5 100%);
//}

.bg-success-gradient {
  background: linear-gradient(to right, #45e1d4 0%, #129ed3 100%);
}

.bg-warning-gradient {
  background: linear-gradient(to right, #f8c888 0%, #f45c6b 100%);
}

.bg-info-gradient {
  background: linear-gradient(to right, #9271fe 0%, #fb5ea3 100%);
}

.bg-white-1 {
  background-color: $white-1;
}

.bg-white-2 {
  background-color: $white-2;
}

.bg-white-3 {
  background-color: $white-3;
}

.bg-white-4 {
  background-color: $white-4;
}

.bg-white-5 {
  background-color: $white-5;
}

.bg-white-6 {
  background-color: $white-6;
}

.bg-white-7 {
  background-color: $white-7;
}

.bg-white-8 {
  background-color: $white-8;
}

.bg-white-9 {
  background-color: $white-9;
}

.bg-black-1 {
  background-color: $black-1;
}

.bg-black-2 {
  background-color: $black-2;
}

.bg-black-3 {
  background-color: $black-3;
}

.bg-black-4 {
  background-color: $black-4;
}

.bg-black-5 {
  background-color: $black-5;
}

.bg-black-6 {
  background-color: $black-6;
}

.bg-black-7 {
  background-color: $black-7;
}

.bg-black-8 {
  background-color: $black-8;
}

.bg-black-9 {
  background-color: $black-9;
}

//.bg-indigo {
//  background-color: #4b0082;
//}
//
//.bg-purple {
//  background-color: #6f42c1;
//}

//.bg-pink {
//  background-color: $secondary !important;
//}

//.bg-orange {
//  background-color: #fd7e14;
//}
//
//.bg-teal {
//  background-color: #00cccc;
//}
//
//.bg-purple-dark {
//  background-color: #59339d;
//}

.bg-success-transparent {
  background-color: rgba(111, 234, 163, 0.2);
}

//.bg-secondary-transparent {
//  background-color: rgba(240, 112, 187, 0.15);
//}
//
//.bg-primary-transparent {
//  background-color: rgba(29, 81, 235, 0.1);
//}

.bg-info-transparent {
  background-color: rgba(1, 184, 255, 0.12);
}

.bg-warning-transparent {
  background-color: rgba(243, 202, 86, 0.15);
}

.bg-danger-transparent {
  background-color: rgba(255, 71, 61, 0.12);
}

//.bg-pink-transparent {
//  background-color: rgba(255, 101, 165, 0.12);
//}
//
//.bg-purple-transparent {
//  background-color: rgba(111, 66, 193, 0.12);
//}
//
//.bg-dark-transparent {
//  background-color: rgba(28, 39, 60, 0.12);
//}

.bg-white-transparent {
  background-color: rgba(255, 255, 255, 0.12);
}

.tag-outline-primary{
   color: var(--primary) !important;
    border-color:var(--primary) !important;
}

.tag-outline-secondary{
   color: var(--secondary) !important;
    border-color: var(--secondary) !important;
}

.tag-outline-success{
   color: $success !important;
    border-color: $success !important;
}

.tag-outline-warning{
   color: $warning !important;
    border-color: $warning !important;
}

.tag-outline-info{
   color: $info !important;
    border-color: $info !important;
}

.tag-outline-danger{
   color: $danger !important;
    border-color: $danger !important;
}



/* ###### Backgrounds ###### */
