.team-status{
  .team-cards{
    display: grid;
    width: 100%;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fit,minmax(350px,1fr));

    .card h3{
      text-transform: uppercase;
      font-weight: bold
    }
  }
}