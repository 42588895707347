input.search-input {
    padding: 5px 5px!important;
    border: 1px solid #e8e8f7;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 14px;
    display: inline-block;
    margin-right: 1rem;
  }
  
  /* Display clear button */
  .search-input::-webkit-search-cancel-button {
    position: absolute;
    right: 10px;
    top: 14px;
    width: 10px;
    height: 10px;
    -webkit-appearance: none;
    background: linear-gradient(
        -45deg,
        transparent 47%,
        transparent 47%,
        rgba(0, 0, 0, 0.7) 47%,
        rgba(0, 0, 0, 0.7) 53%,
        transparent 53%
      ),
      linear-gradient(
        45deg,
        transparent 47%,
        transparent 47%,
        rgba(0, 0, 0, 0.7) 47%,
        rgba(0, 0, 0, 0.7) 53%,
        transparent 53%
      );
  }