.dashboard{

    .card h4{
      margin: 16px 16px 0 16px;
    }

    table{
      border-radius: 4px;

      tr{
        height: 60px;
      }
    }


}