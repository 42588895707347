.users .main-img-user {
  width:40px;
  height:40px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size:21px;
}

.users .avatar-xxl {
  font-size:16px;
  width:40px;
  height:40px;
}

.circle.badge-danger {
  display:block;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #fd6074;
}

.circle.badge-warning {
  display:block;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #ecb529;
}

.circle.badge-success {
  display:block;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #19b159;
}