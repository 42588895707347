/* DateSelect-B */
.MuiPopperUnstyled-root {
    z-index: 10000!important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    z-index: 0 !important;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
    font-size: 14px !important;
    padding-top: 10.5px !important;
    padding-bottom: 10.5px !important;
    font-style: normal !important;
    line-height: 21px !important;
    /* vertical-align: middle !important; */
}
/************DateSelect-A************/
/* Removes the clear button from date inputs */
input[type="date"]::-webkit-clear-button {
    display: none;
}

/* Removes the spin button */
input[type="date"]::-webkit-inner-spin-button { 
    display: none;
}

/* Always display the drop down caret */
input[type="date"]::-webkit-calendar-picker-indicator {
    color: #2c3e50;
}

/* A few custom styles for date inputs */
input[type="date"] {
    appearance: none;
    -webkit-appearance: none;
    color: #95a5a6;
    /* font-family: "Helvetica", arial, sans-serif; */
    font-size: 14px;
    font-style: normal;
    line-height: 21px;
    /* border:1px solid #ecf0f1; */
    /* background:#ecf0f1; */
    padding: 5px 12px !important;
    display: block !important;
    visibility: visible !important;
    border: 1px solid #d1d1d1 !important;
    border-radius: .4vh; 
}

input[type="date"], focus {
    color: #95a5a6;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}