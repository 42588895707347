.task-settings-modal .status-badge {
    margin-left: 16px;
    margin-right: 8px;
}

.task-settings-modal .status-menu {
    right: -20px;
    left: unset;
    top: 35px !important;
    padding-top: 16px;
}

.task-settings-modal .status-menu:before {
    right: 28px;
    left: unset;
}

.task-status-btn {
    display: flex;
    align-items: center;
}