//@keyframes fadeIn {
//  from {
//    opacity: 0;
//    visibility: hidden;
//  }
//  to {
//    opacity: 1;
//    visibility: visible;
//  }
//}

@keyframes fadeOut {
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}

.toast.fadein {
  animation: 0.3s linear 0s fadein;
  animation-fill-mode: forwards;
}

.toast.fadeOut {
  animation: 0.2s linear 0s fadeOut;
}

.toast{
  position: fixed;
  width: 80%;
  margin:16px;
  padding: 16px;
  top:20px;
  left:20px;
  background-color: var(--secondary-dark);
  color:var(--secondary-text-color);
  box-shadow:0px 2px 8px rgba(7, 15, 31, 0.1);
  border-radius:4px;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
}

.toast p{
  color:var(--secondary-text-color);
}

.toast .close{
  color:var(--secondary-text-color);
}

.toast > div{
  display: flex;
  align-items: center;
  svg {
    color:var(--primary);
    width:18px;
    height:18px;
  }
}

.toast p{
  margin-bottom: 0;
  margin-left:16px;
  display:inline;
  font-weight:bold;
}

.toast button:not(.gitlab-toast){
  background-color: transparent;
  border:none;

}
.gitlab-toast svg {
  color:white !important;
}