@import "../../variables";

.main-content.side-content {
  transition: margin-left 0.3s ease;
}

.sidemenu-label {
  transition: margin-left 0.45s ease;
  color:var(--navigation-text-color);
}

.side-menu {
  .nav-link:hover {
    .sidemenu-label {
      margin-left: 5px;
      transition: margin-left 0.45s ease;
    }

    //color: $white;
  }

  .nav-item {
    transition-duration: 0.25s;
    margin: 6px 0;
  }



  .nav-sub {
    max-height: 0;
    overflow: hidden;
    transition: all 0.9s;
  }

  .nav-item.show > .nav-sub {
    max-height: 100vh;
    transition: all 2s;
  }

  .nav-sub-link {
    transition: all .25s;
  }
}

.iconbtn {
  display: none;
}

.side-menu {
  i.angle {
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 12px;
  }

  .nav-item.show i.angle {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 12px;
  }
}

.side-badge, .main-sidebar-open.main-sidebar-hide .side-badge {
  position: absolute;
  top: 12px;
  right: 18px;
  border-radius: 50px;
}

.main-sidebar-hide .side-badge {
	position: absolute;
    top: 0px;
    right: 27px;
    border-radius: 50px;
    z-index: 4;
}

.sidemenu-logo {
  padding: 13.4px 21px;
  border-bottom: 1px solid rgba(240, 240, 255, 0.1);
  justify-content: center;
}

.side-menu .header-brand-img {
  margin-top: 0 !important;
  outline: 0;
}

.main-footer {
  transition: margin-left 0.3s ease;
}


/* ###### Sidebar  ###### */

@media (max-width: 991.98px) {
  .main-sidebar-show {
    overflow: inherit;

    .main-sidebar {
      display: flex;
      transform: translateX(0);
      visibility: visible;
      transition: left 0.3s ease, width 0.3s ease;
    }

    .main-navbar-backdrop {
      opacity: 1;
    }
  }
}

@media (min-width: 992px) {
  .main-sidebar-hide {
    .main-sidebar {
      display: none;
    }

    .main-sidebar-sticky + .main-content {
      margin-left: 0;
    }
  }
}

.main-body-sidebar {
  display: block;

  .main-content {
    flex: 1;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  .main-content-header {
    height: auto;
    padding: 20px;
    margin-bottom: 0;
  }

  .main-content-body {
    padding: 0 20px 20px;
  }

  .main-footer {
    margin-top: auto;
  }
}

@media (min-width: 992px) {
  .main-body-sidebar {
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  .main-body-sidebar .main-header .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.main-sidebar {
  width: 240px;
  flex-direction: column;
  flex-shrink: 0;
  border-right: 0;
  transform: translateX(-240px);
  visibility: hidden;
  transition: all 0.4s;
  transition:left 0.4s ease, width 0.4s ease;
}

@media (prefers-reduced-motion: reduce) {
  .main-sidebar {
    transition: none;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .main-sidebar {
    width: 220px;
  }
}

@media (max-width: 991.98px) {
  .main-sidebar {
    position: fixed;
    top: 0px !important;
    left: 0;
    bottom: 0;
    z-index: 2;
    background-color: $white;
  }
}

@media (min-width: 992px) {
  .main-sidebar {
    position: relative;
    display: flex;
    transform: none;
    z-index: 999;
    border-right-width: 1px;
    visibility: visible;
  }
}

.main-sidebar-sticky {
  position: fixed;
  top: 0px;
  left: 0;
  bottom: 0;
  background: var(--navigation-background);
  box-shadow: -7.829px 11.607px 20px 0px rgb(144 143 160 / 50%);
}

.main-sidebar-body .nav-item.active .nav-link {
  &:before {
    content: "";
    position: absolute;
    top: -30px;
    z-index: 9;
    right: 0px;
    bottom: 0;
    height: 30px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom: transparent;
    border-radius: 0px 0px 48px 0px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 43px;
    z-index: 0;
    right: 0px;
    bottom: 0;
    height: 30px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom: transparent;
    border-radius: 0px 48px 0px 0px;
  }

  .shape1 {
    position: absolute;
    top: -30px;
    width: 20px;
    height: 60px;
    right: 0px;
  }

  .shape2 {
    position: absolute;
    top: 35px;
    width: 20px;
    height: 30px;
    right: 0px;
  }
}

.main-sidebar-body {
  li.nav-item.active .nav-link {
    color:var(--secondary-text-color);
    font-weight:bold;
    background-color: var(--secondary);
    position: relative;
    z-index: 1;
    margin: 10px auto;
    text-align: center;
    border-radius: 30px 0 0 30px;
    margin-left: 0;
    border: 0px solid transparent;
    border-right: 0px;
    width: inherit;

    .sidemenu-label{
      color:var(--secondary-text-color);
    }
  }

  .nav-item.active .nav-link {
    &:before, &:after {
      border-right: 20px solid var(--navigation-background);
    }

    .shape1, .shape2 {
      background: var(--secondary);
    }
  }
}

@media (min-width: 992px) {
  .main-sidebar-sticky + .main-content {
    margin-left: 220px;
  }
}

@media (min-width: 1200px) {
  .main-sidebar-sticky + .main-content {
    margin-left: 240px;
  }
}

.main-sidebar-header {
  flex-shrink: 0;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.main-sidebar-loggedin {
  padding: 10px 20px;
  display: flex;
  flex-shrink: 0;

  .main-img-user {
    width: 36px;
    height: 36px;
  }

  .media-body {
    margin-left: 12px;

    h6 {
      font-weight: 700;
      font-size: 14px;
      color: #8f9cc0;
      margin-bottom: 2px;
    }

    span {
      display: block;
      color: #a8afc7;
    }
  }
}

.main-sidebar-body {
  padding: 0;
}

@media (min-width: 1200px) {
  //.main-sidebar-body {
  //  padding: 12px 0px 12px 16px;
  //}
}

.main-body .main-sidebar-body .nav {
  flex-direction: column;
  padding: 0 0 0 15px;
  margin: 0 auto;
  justify-content: center;
  margin-top: 0;
}

.nav-header {
  margin: 0 0 0 15px;
  display: flex;
  position: relative;
}

.main-sidebar-body .nav-label {
  text-transform: uppercase;
  font-size: 11.49px;
  font-weight: 500;
  letter-spacing: .5px;
  color: var(--navigation-text-color);
  padding: 10px 0px 0 0;
}

.second-sidemenu {
  .nav-label {
    text-transform: uppercase;
    font-size: 11.49px;
    font-weight: 500;
    letter-spacing: .5px;
    color: #77778c;
    padding: 10px 0px 0 0;
  }

  .nav-header li {
    list-style-type: none;
  }
}

.main-sidebar-body .sub-txt {
  font-size: 10.9px;
  padding: 0 0px 0 0;
  color: #9b9ca3;
  margin-bottom: 0;
  position: absolute;
  margin-top: -1px;
}

.second-sidemenu .sub-txt {
  font-size: 10.9px;
  padding: 0 0px 0 0;
  color: #8d95b1;
  margin-bottom: 10px;
}

.main-sidebar-body {
  .nav-item {
    position: relative;
    display: block;

    + .nav-item {
      margin-top: -1px;
    }
  }

  .nav-link {
    display: flex;
    align-items: center;
    padding:4px;
    font-weight: 400;
    font-size: 14px;
    color: var(--navigation-text-color);

    .sidemenu-icon {
      margin-right: 10px;
      width: 20px;
      height: 19px;
      padding: 8px;
      text-align: center;
      border-radius: 50%;
    }

    i.typcn {
      line-height: .9;

      &::before {
        width: auto;
      }
    }

    &.with-sub {
      position: relative;
    }
  }
  .active .nav-sub {
	max-height: 100%;
  }
  .nav-sub {
    list-style: none;
    padding: 0;
    margin-left: 1.2rem;

    .nav-sub-link:before {
      content: '\e048';
      font-family: 'typicons';
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      top: 8px;
      font-size: 12px;
      opacity: 0.4;
      margin-right: 22px;
      color: $white-8;
    }
  }

  .nav-sub-item {
    .nav-sub {
      padding: 0 0 0 12px;
      border-left-width: 1px;
      margin-left: 0;

      .nav-sub-link {
        height: auto;
        padding: 7px 0;
      }
    }

    &.active > .nav-sub-link {
      color: #c6c6e0;
    }

    &.show .nav-sub {
      display: block;
    }
  }

  .nav-sub-link {
    display: flex;
    align-items: center;
    color: $white-4;
    font-size: 13px;
    padding:8px 0;

    &.with-sub {
      justify-content:flex-start;

      &::after {
        content: '\f3d0';
        font-family: 'Ionicons';
        font-size: 12px;
        font-weight: 400;
        display: inline-block;
        position: relative;
        margin-left: auto;
        color: var(--gray);
      }
    }

    + .nav-sub-link {
      border-top: 1px dotted var(--gray);
    }
  }

  li.active {

    .sidemenu-icon {
      margin-right: 10px;
      width: 20px;
      height: 19px;
      padding: 8px;
      text-align: center;
      border-radius: 50%;
      box-shadow: 0 5px 10px $black-2;
      background: var(--secondary-dark);
      color: var(--secondary-text-color);
    }
  }
}

@media (min-width: 992px) {
  .main-sidebar-body .nav-sub-link.with-sub::after {
    margin-left: 4px;
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .main-sidebar-hide {
    .main-sidebar-body li.active:after {
      top: 43px;
    }

    .main-sidebar .main-sidebar-body {
      padding: 17px 0px 12px 0px;
    }

    .main-sidebar-body .nav-link .sidemenu-icon {
      width: 35px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      border-radius: 50%;
      z-index: 1;
      box-shadow: none;
    }

    &.main-sidebar-open .main-sidebar-body {
      li.active {
        position: relative;
        z-index: 1;
        margin: 0 auto;
        text-align: center;
        border-radius: 30px 0 0 30px;
        margin-left: 0;
        border: 0px solid transparent;
        border-right: 0px;
        transition: none;
      }

      .nav-link .sidemenu-icon {
        font-size: 18px;
        line-height: 0;
        margin-right: 10px;
        width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        border-radius: 50%;
        box-shadow: none;
      }

      li.active .sidemenu-icon {
        font-size: 18px;
        margin-right: 10px;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: var(--primary);
        text-align: center;
        border-radius: 50%;
        box-shadow: 0 5px 10px $black-2;
        color: $white;
      }
    }
  }

  .main-body.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav {
    flex-direction: column;
    padding: 0 0 0 15px;
    text-align: inherit;
    margin: 0;
    justify-content: inherit;
  }

  .main-sidebar-hide {
    .main-sidebar-body li {
      &.active {
        .sidemenu-icon {
          background: var(--primary);
          margin-left: 5px;
        }

        margin: 6px 0;
      }

      &.nav-item.active .nav-link {
        width: inherit;
        transition: margin-left 0.3s ease;
      }
    }

    &.main-sidebar-open .main-sidebar-body .nav-item.active .nav-link {
      &:before {
        content: "";
        position: absolute;
        top: -30px;
        z-index: 9;
        right: 0px;
        bottom: 0;
        height: 30px;
        border-top-color: transparent;
        border-left-color: transparent;
        border-bottom: transparent;
        border-radius: 0px 0px 48px 0px;
      }

      &:after {
        content: "";
        position: absolute;
        top: 43px;
        z-index: 0;
        right: 0px;
        bottom: 0;
        height: 30px;
        border-top-color: transparent;
        border-left-color: transparent;
        border-bottom: transparent;
        border-radius: 0px 48px 0px 0px;
      }
    }
  }
}


/* ###### Sidebar  ###### */
@media (min-width: 1200px) {
  .side-content {
    margin-left: 240px;
  }
}

@media (min-width: 992px) {
  .side-header .main-logo {
    color: inherit;
    margin-right: 0;
    font-size: 1.25rem;
    white-space: nowrap;
    font-weight: 600;
    padding: 0;
    transition: .3s opacity;
    line-height: 2.9rem;
    text-align: center;
  }

  .main-sidebar-hide {
    .side-header, .main-footer {
      padding-left: 70px;
    }
  }

  .main-body {
    .side-header {
      position: fixed;
      width: 100%;
      z-index: 10 !important;
      //border-bottom: 1px solid #e1e6f1;
      box-shadow: -7.829px 11.607px 20px 0px rgba(144, 143, 160, 0.09);
      left: 0;
      padding-left: 240px;
    }

    &.main-sidebar-hide .side-header {
      padding-left: 75px;
    }
  }

  .main-sidebar-hide {
    .sidemenu-logo {
      padding: 8px 6px 6px 6px;
      border-bottom: 1px solid rgba(240, 240, 255, 0.1);
      justify-content: center;
      height: 65px;
    }

    &.main-sidebar-open .sidemenu-logo {
      padding: 13.4px;
      border-bottom: 1px solid rgba(240, 240, 255, 0.1);
      justify-content: center;
    }
  }

  .main-content.side-content {
    margin-left: 240px;
  }

  .main-sidebar.side-menu {
    width: 240px;
  }

  .main-footer {
    margin-left: 0;
  }
}

@media (max-width: 992px) and (min-width: 767px) {
  .main-header-menu-icon {
    margin-left: 15px;
  }
}

@media (max-width: 992px) {
  .main-header-menu-icon {
    margin-left: 15px;
  }

  .side-header .icon-logo {
    margin-left: 0;
  }

  //.main-sidebar-show .side-menu {
  //  top: 60px !important;
  //}
}

@media (min-width: 768px) {
  /*main-sidebar-hide*/

	.main-body.main-sidebar-hide.main-sidebar-open .side-menu .nav-link{
	   padding:4px 0px  !important;
	 }


  .main-sidebar-hide {
    .side-menu {
      left: 0;
      width: 80px;
      display: block;
      visibility: visible;
	  transform: none
    }

    .main-content.side-content {
      margin-left: 80px;
    }

    .nav-label, .main-sidebar-body .sub-txt {
      display: none;
    }
  }

  .main-sidebar-body .nav-label-1 {
    height: 28px;
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .5px;
    color: $white;
    padding: 0;
    background: rgba(7, 167, 187, 24%);
    width: 28px;
    border-radius: 50%;
    text-align: center;
    margin: 3px 11px;
    line-height: 2.1;
  }

  .main-sidebar-hide {
    .nav-header {
      margin: 0;
    }

    &.main-sidebar-open .nav-header {
      margin: 0 0 0 15px;
    }

    .sidemenu-label {
      display: none;
      font-size: 10px;
    }

    .nav-item {
      width: 100%;
    }

    .text-icon {
      display: none;
    }

    .iconbtn {
      display: block;
    }

    .main-sidebar-body i.angle {
      display: none;
    }

    .side-menu .nav-link {
      margin: 0 auto;
      padding: 4px 0;
      text-align: center;
      margin-bottom: 0;
    }

    .main-sidebar-body .nav-item {
      + .nav-item {
        margin-top: -1px;
        text-align: center;
      }

      margin-top: -1px;
      text-align: center;
      margin: 6px 5px;
    }

    .side-menu {
      .main-logo {
        .desktop-logo {
          display: none;
        }

        .icon-logo {
          display: block;
		  margin-left:10px;
        }
      }

      .header-brand-img {
        margin-top: 0 !important;
      }
    }

    .main-sidebar-body .nav-item.show > .nav-sub {
      display: none;
    }

    .main-footer {
      margin-left: 0;
    }

    .side-menu {
      .main-sidebar-body .nav-link i {
        font-size: 20px;
      }

      i.angle {
        font-size: 12px !important;
      }
    }
  }


  /*main-sidebar-open*/

  .main-sidebar-open {
    .side-menu {
      left: 0;
      width: 240px;
    }

    .nav-label {
      display: block;
    }

    .main-sidebar-body {
      .sub-txt {
        display: block;
        font-size: 10.9px;
        padding: 0 0px 0 0;
        color: $white-6;
        margin-bottom: 0;
      }

      //.nav-label {
      //  text-transform: uppercase;
      //  font-size: 11.49px;
      //  font-weight: 500;
      //  letter-spacing: .5px;
      //  color: red;
      //  padding: 10px 0px 0 0;
      //}
    }
    .sidemenu-label, .text-icon {
      display: block;
    }

    .iconbtn {
      display: none;
    }

    .main-sidebar-body i.angle {
      display: block;
    }

    .side-menu .nav-link {
      display: flex;
      margin-bottom: 0px;
    }

    .main-sidebar-body .side-menu .nav-link {
      margin-left: 10px;
    }

    .sidemenu-label {
      font-size: 14px;
    }

    .side-menu .main-logo {
      .desktop-logo {
        display: block;
        margin: 0 auto;
        text-align: center;
      }

      .icon-logo {
        display: none;
      }
    }

    .main-sidebar-body .nav-item.show > .nav-sub {
      display: block;
    }

    .side-menu {
      .nav-item.show i.angle {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        position: absolute;
        top: 16px;
        right: 20px;
      }

      .main-sidebar-body .nav-link i {
        margin-right: 5px;
        font-size: 18px;
      }

      i.angle {
        font-size: 12px;
      }
    }
  }
}


.main-header .select2-container .select2-selection--single .select2-selection__rEndered {
  display: block;
  padding-left: 17px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.main-sidebar .nav-sub-item.active .nav-sub-link {
  color: #fff !important;
}

@media (min-width: 768px) {
  .color-leftmenu.main-sidebar-hide .side-menu .main-logo {
    .desktop-logo {
      display: none !important;
    }

    .icon-logo {
      display: block !important;
      margin-left: 10px;
    }
  }

  .leftmenu.color-leftmenu.main-sidebar-hide .header-brand-img.icon-logo.theme-logo {
    display: none !important;
  }

  .color-leftmenu {
    &.main-sidebar-hide .header-brand-img {
      &.desktop-logo.theme-logo, icon-logo theme-logo {
        display: none !important;
      }
    }

    &.leftmenu.main-sidebar-hide.main-sidebar-open .header-brand-img.icon-logo {
      &.theme-logo {
        display: block !important;
        display: none !important;
      }

      display: none !important;
    }

    &.main-sidebar-hide.main-sidebar-open {
      .side-menu .main-logo .desktop-logo {
        display: block !important;
      }

      .main-logo .header-brand-img.desktop-logo.theme-logo {
        display: none !important;
      }
    }
  }

  .light-leftmenu.leftmenu .header-brand-img.desktop-logo {
    display: none !important;

    &.theme-logo {
      display: block !important;
    }
  }

  .leftmenu.light-leftmenu.main-sidebar-hide {
    .header-brand-img {
      &.icon-logo {
        display: none !important;
      }

      &.desktop-logo {
        &.theme-logo {
          display: none !important;
        }

        display: none !important;
      }

      &.icon-logo.theme-logo {
        display: block !important;
		 margin-left: 10px;
         margin-right: 0
      }
    }

    &.dark-theme .header-brand-img {
      &.icon-logo.theme-logo {
        display: block !important;
      }

      &.desktop-logo.theme-logo {
        display: none !important;
      }
    }
  }

  .light-leftmenu.leftmenu.main-sidebar-open .header-brand-img.desktop-logo.theme-logo {
    display: block !important;
  }

  .leftmenu.light-leftmenu.main-sidebar-hide {
    &.main-sidebar-open {
      //.header-brand-img.icon-logo.theme-logo {
      //  display: none !important;
      //}

      //.main-sidebar-body {
      //  .nav-label {
      //    color: #c8c4db;
      //  }
      //
      //  .sub-txt {
      //    color: rgb(162, 162, 185);
      //  }
      //}

    }

    .sidemenu-logo {
      border-bottom: 1px solid #f0f0ff;
    }
  }

  .main-body.leftmenu .sidemenu-logo{
	    position:fixed;
		z-index: 9999;
		width: 240px ;
		background: var(--navigation-background);
	    transition: all 0.4s;
	}
   .main-body.leftmenu  .main-sidebar-body{
	  margin-top:65px;
	  }
	  .main-sidebar-hide .sidemenu-logo{
		position:fixed;
		z-index: 999;
		width:80px !important;
		background:#25233c;
	}
	.main-sidebar-hide.main-sidebar-open .sidemenu-logo{
	  width: 240px !important;
	}
	.main-sidebar-hide .main-sidebar-body{
		margin-top:65px;
	}
	.dark-theme.main-body.leftmenu .sidemenu-logo {
	 background:#0e0e23 ;
   border-right: 1px solid #2b2b3e;
	}
	.color-leftmenu.main-body.leftmenu .sidemenu-logo {
	 background:#6259ca ;
	}
	.dark-theme.light-leftmenu .sidemenu-logo {
	 background:#fff ;
	}
	.dark-theme.light-theme.main-body.leftmenu .sidemenu-logo{
	background: #0e0e23;
	}
	.dark-theme.color-leftmenu.main-body.leftmenu .sidemenu-logo {
	 background:#6259ca ;
	}
	.dark-theme.light-leftmenu.main-body.leftmenu .sidemenu-logo{
	background:#fff ;
	}
}

*::-ms-backdrop, .side-header {
  position: relative !important;
}
*::-ms-backdrop,  .main-sidebar-body .nav-sub .nav-sub-link:before{
 top:0;
}

.side-menu .nav-sub-item.show > .nav-sub {
  max-height: 100vh;
}

.side-menu .nav-sub-item > .nav-sub {
  max-height: 0;
}

.main-sidebar-body .nav-sub-link.with-sub {
  position: relative;
  line-height: 2;
}
.main-sidebar-body .nav-sub-item .nav-sub-link.with-sub::after {
  display: none;
}
.side-menu .nav-item.show .nav-sub {
  .nav-sub-link.with-sub i.angle {
    transform: rotate(0deg);
  }

  .nav-sub-item.show {
    .nav-sub-link.with-sub i.angle {
      transform: rotate(90deg);
    }

    .nav-sub {
      .nav-sub-link.with-sub i.angle {
        transform: rotate(0deg);
      }

      .nav-sub-item.show .nav-sub-link.with-sub i.angle {
        transform: rotate(90deg);
      }
    }
  }
}
.nav-sub .side-menu-label1{
 display:none;
}

////////////////rtl/////////////////
@media (min-width: 992px){
	.rtl.main-sidebar-hide .main-content.side-content {
		margin-right: 80px !important;
		margin-left: initial;
	}
}
.rtl .nav {
    padding-right: 0;
    padding-left: initial !important;
}

//@media (min-width: 992px) and (max-width:992.9px) {
.main-header~.main-content{
      padding-top: 64px !important;
  }
//}
//@media (min-width: 993px) {
//  .main-content.side-content {
//      padding-top: 0px !important;
//  }
//}
