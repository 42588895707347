h5.task-item-status {
    color: #acacc4 !important;
  }
  
  .transform-45 {
    transform: rotate(-15deg);
  }
  
  .tasks-flex {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .name-flex-inner {
    display: flex;
    align-items: center;
    width: 180px;
  }
  .date-flex-inner {
    display: flex;
    align-items: center;
    width: 186px;
  }
  
  .date-task-item {
    margin-right: 15px;
    /* width: 125px; */
    flex-basis: 125px;
  }
  .task-item {
   margin-right: 15px;
    svg{
      height: 18px;
    }
  }
  
  .span-task-item {
    //display: flex;
    //align-items: center;
    //justify-content: space-around;
    //height: 30px;
    width:70px;
  }