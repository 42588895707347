.message-page{
    padding-top:100px;
    color:white;
    width:100%;
    text-align: center;
    min-height: 100vh;
    background-color: #02182B;
}

.message-page h1{
    font-size:2rem;
    margin:30px 0;
}
